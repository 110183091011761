<template>
  <div class="login-container height-100 light-grey-bg">
      <Login :sign="sign" :loading="loading"/>
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import Login from '@/components/Login/Login.vue';
import notification from 'ant-design-vue/lib/notification';
import { mapGetters } from 'vuex';

export default defineComponent({
  name: 'LoginView',
  components: { Login },
  data() {
    return {
      prevPath: null,
      loading: false,
    };
  },
  computed: {
    ...mapGetters('user', [
      'getIsLoggedIn',
    ]),
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      // eslint-disable-next-line
      vm.prevPath = from.path;
    });
  },
  methods: {
    async sign(email, password, rememberMe) {
      try {
        this.loading = true;
        const response = await this.$store.dispatch('user/updateIsLoggedIn', {
          email,
          password,
          rememberMe,
        });
        if (this.getIsLoggedIn) {
          this.$gtag.event('login', {
            event_label: 'Sign in Authenticated',
          });
        } else {
          if (!(response.data && response.data.message)) throw new Error();
          notification.error({
            description: response.data.message,
          });
        }
      } catch (e) {
        notification.error({
          description: 'Please check your email to activate your account.',
        });
      } finally {
        this.loading = false;
      }
    },
    nextPath() {
      let next = '/';
      if (this.$route.redirectedFrom) {
        next = this.$route.redirectedFrom.path;
      } else if (this.prevRoute) {
        next = this.prevRoute;
      }
      return next;
    },
  },
  watch: {
    getIsLoggedIn(isLogged) {
      if (isLogged) {
        const query = { ...this.$route.query };
        delete query.next;
        this.$router.push({
          path: this.nextPath(),
          query,
        });
      }
    },
  },
});
</script>

<style>
.login-container {
  padding-top: 3rem;
}
</style>
